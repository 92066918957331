import React from 'react'
import Page from "components/page"
import Layout from "components/layout"
import SEO from "components/seo"

export const ContentPage = ({ pageContext, ...props }) => {
    const { title, html, seo } = pageContext
    return (
        <Page>
            <SEO page={seo} />

            <Layout {...props} className={"c-content u-overflow-hidden"}>
                <section className="c-headband u-pd-top-navbar">
                    <div className="l-container-md">
                        <div className="c-headband__content u-center u-pd-vt-m u-pd-vt-xl@main">
                            <h1 className="c-h1" dangerouslySetInnerHTML={{__html: title}}></h1>
                        </div>
                    </div>
                </section>

                <section className="u-pd-vt-l u-fadeInUp">
                    <div className="l-container-lg u-pd-hz-l">
                        <div className="c-wysiwyg" dangerouslySetInnerHTML={{__html: html}}></div>
                    </div>
                </section>
            </Layout>
        </Page>
    )
}

export default ContentPage